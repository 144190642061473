<template>
  <div>
    <div v-for="(value, index) in row[header]" :key="index">
      {{ index }}: {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["header", "row", "result"],
  methods: {
    triggerFilterChange(newFilter) {
      let filters = this.result.table.groupFilterExplorer;
      console.log(filters);
      if (!Array.isArray(filters)) {
        filters = [];
      }
      let exist = false;
      filters.forEach((element) => {
        if (element.value == newFilter) {
          exist = true;
          return;
        }
      });
      if (!exist) {
        filters.push({ text: newFilter, value: newFilter });
        let result = this.result;
        this.$set(result.table, "groupFilterExplorer", filters);
        this.$store.commit("resultChanged", result);
        this.$root.$refs.explorerTagFilter.applyFilter(filters);
      }
    },
  },
};
</script>